input[type="radio"] {
    -ms-transform: scale(1.8);
    -webkit-transform: scale(1.8);
    transform: scale(1.8);
    margin-top: 8px;
}


input[type="checkbox"] {
    -ms-transform: scale(1.8);
    -webkit-transform: scale(1.8);
    transform: scale(1.8);
    margin-top: 8px;
}